var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', {
    staticClass: "p-5"
  }, [_c('SpinnerLogo')], 1) : _vm.reportComponent ? _c(_vm.reportComponent, {
    tag: "component"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }